import type { PayoutDetailsSchema } from "@adyen/kyc-components/dist/types/components/PayoutDetails/types";
import type { TokenResponse } from "@adyen/kyc-components/dist/types/core/context/AuthContext/AuthProvider";
import "@adyen/kyc-components/styles.css";
import { Controller } from "@hotwired/stimulus";

interface Session {
  legalEntityId: string;
  allowBankAccountFormatSelection: boolean;
  allowIntraRegionCrossBorderPayout: boolean;
  componentsEnvironment: string;
  componentsToken: string;
  countryCode: string;
  locale: string;
}

export default class AdyenComponentsController extends Controller {
  static values = {
    sessionUrl: String,
    successUrl: String,
    mode: String,
  };

  declare sessionUrlValue: string;
  declare successUrlValue: string;
  declare modeValue: "create" | "manage";

  async connect(): Promise<void> {
    const { default: AdyenComponents } = await import("@adyen/kyc-components");

    const session = await this.getSession();

    const adyenComponents = new AdyenComponents({
      locale: session.locale,
      // @ts-expect-error: Suppressing country code type error
      country: session.countryCode,
      environment: session.componentsEnvironment,
      sdkToken: session.componentsToken,
      getSdkToken: this.getSdkToken.bind(this),
    });

    switch (this.modeValue) {
      case "create":
        adyenComponents
          .create("createTransferInstrumentComponent", {
            legalEntityId: session.legalEntityId,
            settings: {
              allowIntraRegionCrossBorderPayout:
                session.allowIntraRegionCrossBorderPayout,
              allowBankAccountFormatSelection:
                session.allowBankAccountFormatSelection,
            },
            onSubmitSuccess: (_submittedData: PayoutDetailsSchema) => {
              fetch(this.successUrlValue, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  credentials: "same-origin",
                },
              })
                .then((response) => {
                  if (response.ok) {
                    window.location.href = response.url;
                  }
                  throw new Error("Network response was not ok");
                })
                .catch((error) => {
                  console.error(
                    "There has been a problem with your fetch operation:",
                    error,
                  );
                });
            },
          })
          .mount(this.element as HTMLElement);
        break;
      case "manage":
        adyenComponents
          .create("manageTransferInstrumentComponent", {
            legalEntityId: session.legalEntityId,
            onAdd: (_legalEntityId: string) => {
              throw new Error("Not implemented");
            },
            onEdit: (_transferInstrumentId: string, _legalEntityId: string) => {
              throw new Error("Not implemented");
            },
            onRemoveSuccess: (
              _transferInstrumentId: string,
              _legalEntityId: string,
            ) => {
              throw new Error("Not implemented");
            },
          })
          .mount(this.element as HTMLElement);
        break;
      default:
        console.error("Invalid mode", this.modeValue);
        break;
    }
  }

  async getSession(): Promise<Session> {
    const response = await fetch(this.sessionUrlValue);
    return await response.json();
  }

  async getSdkToken(): Promise<TokenResponse> {
    const session = await this.getSession();
    return { token: session.componentsToken };
  }
}
